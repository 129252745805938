@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");
* {
  font-family: "Inter", sans-serif;
  font-family: "Poppins", sans-serif;
  font-family: "Work Sans", sans-serif;
  overflow:visible
}

:root {
  --orange-light: #f1e7da
}

#br {
  position: relative;
  text-align: center; /* Centraliza o texto no elemento, se necessário */
}

#br::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Ajuste a distância conforme necessário */
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4px; /* Altura do traço */
  background-color: #fd6d58; /* Cor do traço */
}

#br2 {
  position: relative;
}

#br2::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Ajuste a distância conforme necessário */
  left: 0;
  width: 10%;
  height: 4px; /* Altura do traço */
  background-color: #fd6d58; /* Cor do traço */
}

body{
  background: linear-gradient(to bottom, #fcf9f8, #fff8f3);
}


#button-profile{
  color: #fff;
  background-color: #fd6d58;
  text-decoration: none;
  border-radius: 20px;
  padding: 0.8rem;
}

#top-button:hover{
  opacity: 0.8;
}

#button-profile:hover {
  opacity: 0.9;
  border: solid 1px #fd6d58;
  color: #fd6d58;
  background-color: transparent;
}

#button-cv:hover {
  opacity: 0.9;
  background-color: #fd6d58;
  color: #fff;
}

#button-cv{
  color: #fd6d58;
  text-decoration: none;
  border-radius: 20px;
  border: solid 1px #fd6d58;
  padding: 0.8rem;
}

#top-button{
  color: #fd6d58;
}



.hover-effect {
  transition: transform 0.3s; /* Adiciona uma transição suave na propriedade transform */
}

.hover-effect:hover {
  transform: scale(1.2); /* Ajuste o valor conforme necessário para o aumento desejado */
}

.hover-effect-projects:hover {
  transform: scale(1.05); /* Ajuste o valor conforme necessário para o aumento desejado */
}
.hover-effect-projects {
  transition: transform 0.3s; /* Ajuste o valor conforme necessário para o aumento desejado */
}
.hover-effect-img:hover {
  transform: scale(1.05); /* Ajuste o valor conforme necessário para o aumento desejado */
}
.hover-effect-img {
  transition: transform 0.3s; /* Ajuste o valor conforme necessário para o aumento desejado */
}